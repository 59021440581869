import React from "react";
import { Link } from "react-router-dom";
import useContent from "../../services/contentful/content";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

const GroupContactInfo = () => {
  const [content, isLoading] = useContent("USzIB0BoIaKp2NczaOvEZ");

  if (isLoading) return <p>Loading...</p>;

  const flexContainerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "0px",
  };
  

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-8 col-md-9 internal-page order-md-2">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/GroupBenefits">Employer group plans</Link>
                        </li>
                        <li>Group contact information</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>

            <h1 className="page-header">Contact us</h1>

            <article className="page full clearfix">
              <div className="content">
                <div className="field field--name-body field--type-text-with-summary field--label-hidden field__item">
                  {/* begin page */}
                  <p>
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={content.bodyText}
                    />
                  </p>



                  <div className="flex-container contactbox" style={flexContainerStyle}>
                    {/* <div style={buttonRowStyle}> */}
                        <div
                          style={{
                            marginLeft: 0,
                            marginRight: "10px",
                            width: "250px",
                          }}
                        >
                          <Link to="/contact/contact-form-online">
                            <img
                              src={
                                content.contentModules[0].fields.icon.fields.file
                                  .url
                              }
                              alt="icon"
                            />
                          </Link>
                          <h3>
                            <Link to="/contact/contact-form-online">
                              {content.contentModules[0].fields.heading}
                            </Link>
                          </h3>
                          <p>
                            <ReactMarkdown
                              rehypePlugins={[rehypeRaw]}
                              children={
                                content.contentModules[0].fields.shortBodyText
                              }
                            />
                          </p>
                        </div>
                        <div
                          style={{
                            marginLeft: 0,
                            marginRight: "10px",
                            width: "250px",
                          }}
                        >
                          <Link to="/mbrx-change-payment-option">
                            <img
                              src={
                                content.contentModules[1].fields.icon.fields.file
                                  .url
                              }
                              alt="icon"
                            />
                          </Link>
                          <h3>
                            <Link to="/mbrx-change-payment-option">
                              {content.contentModules[1].fields.heading}
                            </Link>
                          </h3>
                          <p>
                            <ReactMarkdown
                              rehypePlugins={[rehypeRaw]}
                              children={
                                content.contentModules[1].fields.shortBodyText
                              }
                            />
                            <br />
                            <br />
                            &nbsp;&nbsp;
                          </p>
                        </div>
                      {/* </div> */}
                    


                      {/* <div style={buttonRowStyle}> */}
                    
                      <div
                        style={{
                          marginLeft: 0,
                          marginRight: "10px",
                          width: "250px",
                        }}
                      >
                        <Link to="/mbrx-update-contact-information">
                          <img
                            src={
                              content.contentModules[2].fields.icon.fields.file
                                .url
                            }
                            alt="icon"
                          />
                        </Link>
                        <h3>
                          <Link to="/mbrx-update-contact-information">
                            {content.contentModules[2].fields.heading}
                          </Link>
                        </h3>
                        <p>
                          <ReactMarkdown
                            rehypePlugins={[rehypeRaw]}
                            children={
                              content.contentModules[2].fields.shortBodyText
                            }
                          />
                          <br />
                          <br />
                          &nbsp;&nbsp;
                        </p>
                      </div>

                      <div
                        style={{
                          marginLeft: 0,
                          marginRight: "10px",
                          width: "250px",
                        }}
                      >
                        <Link to="/group-materials">
                            <img
                              src={
                                content.contentModules[0].fields.icon.fields.file
                                  .url
                              }
                              alt="icon"
                            />
                        </Link>
                        <h3>
                          <Link to="/group-materials">
                            Group Materials
                          </Link>
                        </h3>
                        <p>
                          Access Group Materials online.
                          <br />
                          <br />
                          &nbsp;&nbsp;
                        </p>
                      </div>
                    {/* </div> */}

                  </div>
                  {/* end link boxes */}

                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    children={content.bodyText2}
                  />

                  <article
                    className="callout"
                    style={{ padding: "0.1px 20px 20px", marginTop: "2rem" }}
                  >
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      children={content.bodyText3}
                    />
                  </article>
                  {/* end page */}
                </div>
              </div>
            </article>
          </div>
        </section>

        {/* <aside
          className="col-sm-4 col-md-3 side-section order-md-1"
          role="complementary"
        >
          <div className="region region-sidebar-second">
            <ul className="menu menu--main nav navbar-nav">
              <li className="first med-group-benefit-solutions">
                <Link to="/EmployerGroupPlans/PartDPlansEmployerAndUnionGroups">
                  Group benefit solutions
                </Link>
              </li>
              <li className="med-group-resources">
                <Link to="/EmployerGroupPlans/GroupResources">
                  Group resources
                </Link>
              </li>
              <li className="med-2021-group-documents">
                <Link to="/EmployerGroupPlans/EmployerGroupDocs2022">
                  2022 Group documents
                </Link>
              </li>
              <li className="med-group-coverage-tools">
                <Link to="/EmployerGroupPlans/GroupPlanResearchTools">
                  Group coverage tools
                </Link>
              </li>
              <li className="active active-trail med-group-contact-information">
                <Link
                  to="/employer-group-plans/group-contact-information"
                  className="active-trail is-active"
                >
                  Group contact information
                </Link>
              </li>
              <li className="last med-fraud,-waste-and-abuse-information">
                <Link to="/EmployerGroupPlans/FWA">
                  Fraud, waste and abuse information
                </Link>
              </li>
            </ul>
          </div>
        </aside> */}
      </div>
    </div>
  );
};

export default GroupContactInfo;
