import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import contactData from "../../state/data/contactData";
import moment from "moment";
import { Button } from "@material-ui/core";
import clearContactData from "../../state/actions/clearContactData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { submitContact } from "../../api/contactApi";
import axios from "axios";
import CSPhoneInput from "../Common/CSPhoneInput";
import { browserName, browserVersion } from "react-device-detect";
import { getTelemetry } from "../../utility/telemetry";

const GroupMaterials = ({ history }) => {
  const {
    register,
    formState: { errors, isValid, isDirty },
    trigger,
    getValues,
    setValue,
    control,
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  //let history = useHistory();

  const [formValues, setFormValues] = useState(contactData);
 

  const handleChange = (input) => (e) => {
    setFormValues({ ...formValues, [input]: e.target.value });
  };

  const handlePlanYearChange = (input) => (e) => {
    setFormValues({
      ...formValues,
      DirectoryPlanYear: e.target.value,
      //StateOfDirectory: "",
    });
    //setValue("StateOfDirectory", ""); // sync useForm form validation
    //setValue("StateOfDirectory", "", { shouldValidate: true });
  };

   const getIpAddress = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");

    let ip = JSON.stringify(res.data.IPv4);

    // console.log(ip);

    return res.data.IPv4;
  };

  const [isProcessing, setIsProcessing] = useState(false);
  const [dobError, setDOBError] = useState(false);
  const [birthDate, setBirthDate] = useState(null);

  let bdErr = false;

  const handleSubmit = async () => {
    //trigger();

    const isValidForm = await trigger();  

    console.log("Is Valid Form: " + isValidForm);
    console.log("Errors: " + JSON.stringify(errors, null, 2));


    if (isValidForm) {
      setIsProcessing(true);

      let f = formValues;

      f.RcptDate_and_time = moment().format("MM/DD/YYYY h:mm:ss a");
      f.timestamp = moment().format("MM/DD/YYYY h:mm:ss a");
      f.ipaddress = ""; //await getIpAddress();
      //f.Request_Type = contactType;
      f.browsername = browserName;
      f.browserversion = browserVersion;
      f.telemetry = getTelemetry();

      //let s = await submitContact(f);

     // let o = JSON.parse(s);

      // console.log("Submit contact success: " + JSON.stringify(s));
      // console.log("Confirmation: " + o.ConfNumber);
      // console.log("Response: " + s);

      
      // console.log("Checked: " + checkedOne);

      await sleep(3000);

    //   if (s.ConfNumber !== "") {        
    //     setFormValues({ ...formValues, ConfNumber: o.ConfNumber });
            //history.push("/EmployerGroupPlans/EmployerGroupDocs2025");
            window.location.href = 'http://assets.ctfassets.net/xfdtsvzh7hmn/7hPgwZHnXWAtatAQ7cXXsW/b7ac1bc8fb0a3b8abb0225685e542838/7074_MED_CSTN_IND_CSTNBrochureFlyerForWeb_508c_R2-1.pdf';
    //   } else if (!checkedOne) {
    //     setDisplayCheckOptionError(true);
    //   }
    }
    setIsProcessing(false);
  };

  const formName = "GroupMaterials";

  const setDefaultValues = useCallback(() => {
    if (formValues.activeForm !== formName) {
      reset(contactData);
    }
  }, [formValues]);

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    setDefaultValues();
  }, []);

  var d = new Date();

  const handleBirthDate = (e) => {
    let date = moment(e).format("YYYY-MM-DD");
    setBirthDate(e);
    setFormValues({ ...formValues, dob: date });
  };

  // console.log("Birthdate: " + formValues.dob);

  return (
    <div
      role="main"
      className="main-container pt-md-5 pb-5 container js-quickedit-main-content"
    >
      <div className="row d-md-flex">
        <section className="col-sm-8 col-md-9 internal-page order-md-2">
          <div className="region region-content">
            <section
              id="block-clearstonebreadcrumbs"
              className="block block-clearstone-updates block-clearstone-breadcrumbs-block clearfix"
            >
              <div className="breadcrumb-wrap">
                <div className="region--breadcrumb nst-1">
                  <section
                    id="block-ttheme-breadcrumbs"
                    className="block-system block-system-breadcrumb-block"
                  >
                    <div role="navigation">
                      <ol className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>GROUP MATERIALS</li>
                      </ol>
                    </div>
                  </section>
                </div>
              </div>
            </section>
            <br />
            <article className="webform full clearfix">
              <div className="content">
                <div className="field field--name-body field--type-text-with-summary field--label-hidden field__item">
                  <p>
                    Only current members can use our online group materials. To
                    complete the form, you will need your member ID number. If
                    you don’t know your member ID number or want to order group materials in large-print or
                    braille format, please{" "}
                    <Link to="/Contact/medicareblue-rx">
                      call our customer service team
                    </Link>
                    . Group MedicareBlue Rx members should contact the{" "}
                    <Link to="/employer-group-plans/group-contact-information">
                      group customer service team
                    </Link>
                    .
                  </p>
                </div>

                <div className="field field--name-webform field--type-webform field--label-hidden field__item">
                  <form
                    className="webform-submission-form webform-submission-add-form webform-submission-contact-form webform-submission-contact-add-form webform-submission-contact-node-166-form webform-submission-contact-node-166-add-form js-webform-details-toggle webform-details-toggle"
                    action="contact-form-online.html"
                    method="post"
                    id="webform-submission-contact-node-166-add-form"
                    acceptCharset="UTF-8"
                  >
                    <fieldset
                      className="radios--wrapper fieldgroup form-composite webform-composite-visible-title required js-webform-type-radios webform-type-radios js-form-item form-item js-form-wrapper form-wrapper"
                      id="edit-request-type--wrapper"
                    >
                      <legend>
                        <span className="fieldset-legend js-form-required form-required">
                          Please, fill out the following form to access group materials...
                        </span>
                      </legend>
                    </fieldset>

                    <div
                      className="hide-panel-heading js-webform-states-hidden form-item js-form-item form-wrapper js-form-wrapper panel panel-default"
                      id="edit-field-display"
                    >
                      <div className="panel-heading">
                        <div className="panel-title">Field Display</div>
                      </div>

                      <div className="panel-body">
                        <div
                          className="row form-group js-form-wrapper form-wrapper"
                          id="edit-row-1-container"
                        >
                          <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-firstname js-form-item-firstname form-group">
                            <label
                              htmlFor="edit-firstname"
                              className="control-label form-required"
                            >
                              First name
                            </label>

                            <input
                              {...register("FirstName", {
                                required: "First name is required.",
                                maxLength: {
                                  value: 25,
                                  message:
                                    "First name field has a maximum length of 25.",
                                },
                                pattern: {
                                  value: /^[-a-zA-Z0-9 ]*$/,
                                  message:
                                    "Special characters are not allowed.",
                                },
                              })}
                              value={formValues.FirstName}
                              onChange={handleChange("FirstName")}
                              pattern="^[-a-zA-Z0-9 ]*$"
                              className="form-text required form-control"
                              data-msg-maxlength="First name field has a maximum length of 25."
                              data-msg-required="First name is required."
                              data-msg-pattern="Special characters are not allowed."
                              type="text"
                              id="edit-firstname"
                              size="60"
                              maxLength="25"
                              required="required"
                              aria-required="true"
                            />
                            <div className="error">
                              <ErrorMessage errors={errors} name="FirstName">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-lastname js-form-item-lastname form-group">
                            <label
                              htmlFor="edit-LastName"
                              className="control-label form-required"
                            >
                              Last name
                            </label>

                            <input
                              {...register("LastName", {
                                required: "Last name is required.",
                                maxLength: {
                                  value: 25,
                                  message:
                                    "Last name field has a maximum length of 25.",
                                },
                                pattern: {
                                  value: /^[-a-zA-Z0-9 ]*$/,
                                  message:
                                    "Special characters are not allowed.",
                                },
                              })}
                              value={formValues.LastName}
                              onChange={handleChange("LastName")}
                              pattern="^[-a-zA-Z0-9 ]*$"
                              data-webform-pattern-error="Special characters are not allowed."
                              data-drupal-selector="edit-lastname"
                              className="form-text required form-control"
                              data-msg-maxlength="Last name field has a maximum length of 25."
                              data-msg-required="Last name is required."
                              data-msg-pattern="Special characters are not allowed."
                              type="text"
                              id="edit-lastname"
                              size="60"
                              maxLength="25"
                              required="required"
                              aria-required="true"
                            />
                            <div className="error">
                              <ErrorMessage errors={errors} name="LastName">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                        </div>
                       
                        <div
                          className="row form-group js-form-wrapper form-wrapper"
                          id="edit-row-4-container"
                        >
                        
                          <div className="col-sm-6 col-md-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-phonenumber js-form-item-phonenumber form-group">
                            <label
                              htmlFor="edit-phonenumber"
                              className="control-label form-required"
                            >
                              Phone number
                            </label>

                            <CSPhoneInput
                              name="PhoneNumber"
                              id="edit-PhoneNumber"
                              value={formValues.PhoneNumber}
                              onChange={handleChange("PhoneNumber")}
                              control={control}
                              rules={{
                                required: "Home phone number required.",
                              }}
                            />                          
                            <div className="error">
                              <ErrorMessage errors={errors} name="PhoneNumber">
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row form-group js-form-wrapper form-wrapper"
                          id="edit-row-5-container"
                        >
                          <div className="col-md-6 col-sm-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-memberidnumber js-form-item-memberidnumber form-group">
                            <label
                              htmlFor="edit-memberidnumber"
                              className="control-label form-required"
                            >
                              Member ID
                            </label>

                            <input
                              {...register("MemberIDNumber", {
                                required:
                                  "MedicareBlue Rx member number (from your plan ID card) is required.",
                                maxLength: {
                                  value: 11,
                                  message:
                                    "MedicareBlue Rx member number (from your plan ID card) field has a maximum length of 9.",
                                },
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Valid Member ID is required",
                                },
                                validate: () => console.log("field validated"),
                              })}
                              value={formValues.MemberIDNumber}
                              onChange={handleChange("MemberIDNumber")}
                              className="form-text required form-control"
                              type="text"
                              id="edit-memberidnumber"
                              size="60"
                              maxLength="9"
                              required="required"
                              aria-required="true"
                            />
                            <div className="error">
                              <ErrorMessage
                                errors={errors}
                                name="MemberIDNumber"
                              >
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>


                          <div className="col-md-6 col-sm-6 form-item js-form-item form-type-textfield js-form-type-textfield form-item-memberidnumber js-form-item-groupidnumber form-group">
                            <label
                              htmlFor="edit-groupidnumber"
                              className="control-label form-required"
                            >
                              Group ID
                            </label>

                            <input
                              {...register("GroupIDNumber", {
                                required:
                                  "Group ID (from your plan ID card) is required.",
                                maxLength: {
                                  value: 11,
                                  message:
                                    "Group ID (from your plan ID card) field has a maximum length of 9.",
                                },
                                pattern: {
                                  value: /^[0-9]*$/,
                                  message: "Valid Group ID is required",
                                },
                                validate: () => console.log("field validated"),
                              })}
                              value={formValues.GroupIDNumber}
                              onChange={handleChange("GroupIDNumber")}
                              className="form-text required form-control"
                              type="text"
                              id="edit-groupidnumber"
                              size="60"
                              maxLength="9"
                              required="required"
                              aria-required="true"
                            />
                            <div className="error">
                              <ErrorMessage
                                errors={errors}
                                name="GroupIDNumber"
                              >
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>


                          <div
                            className="col-md-12 js-webform-states-hidden form-item js-form-item form-type-select js-form-type-select form-item-plan-year js-form-item-plan-year form-group"
                           
                          >
                            <label
                              htmlFor="edit-plan-year"
                              className="control-label form-required"
                            >
                              Plan year
                            </label>

                            <div className="select-wrapper">
                              <select
                                {...register("DirectoryPlanYear", {
                                  validate: {
                                    required: (value) => {
                                      if (
                                        !value 
                                      )
                                        return "Plan year selection is required.";
                                      return true;
                                    },
                                  },
                                })}
                                value={formValues.DirectoryPlanYear}
                                onChange={handlePlanYearChange()}
                                className="form-select form-control"
                                id="edit-plan-year"
                                name="DirectoryPlanYear"
                              >
                                <option value="">- Select -</option>
                                {/* <option value="2022">2022</option> */}
                                {/* <option value="2023"
                                {...((contactType === "Rx Formulary Drug List") && {
                                  style: { display: "none" },
                                })}>                                
                                  2023
                                </option> */}
                                <option value="2025">2025</option>
                                {/* <option value="2024">2024</option> */}
                              </select>
                            </div>
                            <div className="error">
                              <ErrorMessage
                                errors={errors}
                                name="DirectoryPlanYear"
                              >
                                {({ messages }) =>
                                  messages &&
                                  Object.entries(messages).map(
                                    ([type, message]) => (
                                      <p key={type}>{message}</p>
                                    )
                                  )
                                }
                              </ErrorMessage>
                            </div>
                          </div>


                        </div>
                      </div>
                      <div
                        className="js-webform-states-hidden form-actions webform-actions form-group js-form-wrapper form-wrapper"
                        id="edit-actions"
                      >
                        <Button
                          className="
                                  webform-button--next
                                  button
                                  js-form-submit
                                  form-submit
                                  btn-default btn"
                          onClick={handleSubmit}
                          disabled={isProcessing}
                          {...(isProcessing && {
                            style: { color: "black" },
                          })}
                        >
                          {isProcessing ? (
                            <div>
                              <span
                                className="spinner-border pmd-spinner spinner-border-sm text-light mr-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              Processing...
                            </div>
                          ) : (
                            "Send"
                          )}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </article>
          </div>
        </section>
      </div>
    </div>
  );
};

export default GroupMaterials;
